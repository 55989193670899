/* Base Styles */
:root {
  --primary-glow: #00f2ff;
  --secondary-glow: #33f5ff;
  --accent-glow: #ff0080;
  --background-dark: #000000;
  --text-primary: rgba(255, 255, 255, 0.9);
  --text-secondary: rgba(255, 255, 255, 0.7);
  --gradient-primary: linear-gradient(45deg, var(--primary-glow), var(--secondary-glow));
  --gradient-accent: linear-gradient(45deg, var(--secondary-glow), var(--accent-glow));
}

/* Modern Reset */
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: var(--background-dark);
  color: var(--text-primary);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  overflow-x: hidden;
}

/* Advanced Background Effect */
.App {
  position: relative;
  min-height: 100vh;
  background: radial-gradient(circle at 50% 50%, 
    rgba(0, 0, 0, 0.8) 0%, 
    rgba(0, 0, 0, 0.95) 100%
  );
}

.App::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    linear-gradient(45deg, 
      transparent 0%, 
      rgba(0, 242, 255, 0.03) 50%, 
      transparent 100%
    );
  pointer-events: none;
  z-index: 1;
}

/* Futuristic Grid Overlay */
.App::after {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    linear-gradient(90deg, rgba(255,255,255,0.03) 1px, transparent 1px) 0 0 / 50px 50px,
    linear-gradient(rgba(255,255,255,0.03) 1px, transparent 1px) 0 0 / 50px 50px;
  pointer-events: none;
  z-index: 2;
}

/* Animated Glow Effects */
.glow-effect {
  position: relative;
  overflow: hidden;
}

.glow-effect::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(0, 242, 255, 0.2),
    transparent
  );
  transform: skewX(-25deg);
  animation: glow 3s infinite;
}

@keyframes glow {
  0% { left: -100%; }
  100% { left: 200%; }
}

/* Futuristic Text Styles */
.text-gradient {
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: textShimmer 3s infinite;
}

@keyframes textShimmer {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Hover Effects */
.hover-glow {
  transition: all 0.3s ease-in-out;
}

.hover-glow:hover {
  box-shadow: 0 0 20px var(--primary-glow);
  transform: translateY(-2px);
}

/* Animated Background Particles */
.particles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 0;
}

.particle {
  position: absolute;
  width: 2px;
  height: 2px;
  background: var(--primary-glow);
  border-radius: 50%;
  animation: float 20s infinite;
}

@keyframes float {
  0% {
    transform: translateY(0) translateX(0);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(-100vh) translateX(100vw);
    opacity: 0;
  }
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 8px;
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
  background: var(--gradient-primary);
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

/* Loading Animation */
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid transparent;
  border-top-color: var(--primary-glow);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Card Styles */
.card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease-in-out;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(0, 242, 255, 0.2);
}

/* Button Styles */
.button-neon {
  position: relative;
  padding: 12px 24px;
  background: transparent;
  border: 1px solid var(--primary-glow);
  color: var(--primary-glow);
  border-radius: 5px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.button-neon::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--primary-glow);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

.button-neon:hover {
  color: var(--background-dark);
}

.button-neon:hover::before {
  opacity: 1;
}

/* Text Selection */
::selection {
  background: var(--primary-glow);
  color: var(--background-dark);
}

/* Responsive Design */
@media (max-width: 768px) {
  .App {
    padding: 10px;
  }
  
  .card {
    margin: 8px;
    padding: 15px;
  }
  
  .button-neon {
    padding: 8px 16px;
    font-size: 14px;
  }
  
  .grid-container {
    grid-template-columns: 1fr;
    gap: 15px;
    padding: 10px;
  }
}

/* Improved Mobile Touch Targets */
@media (max-width: 480px) {
  .button-neon,
  .card,
  .hover-glow {
    min-height: 44px; /* Apple's recommended minimum touch target size */
    margin-bottom: 12px;
  }
  
  .text-gradient {
    font-size: 85%;
    line-height: 1.4;
  }
}

/* Animation Classes */
.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

.slide-up {
  animation: slideUp 0.5s ease-in-out;
}

.scale-in {
  animation: scaleIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes scaleIn {
  from { transform: scale(0.9); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

/* Glass Effect */
.glass {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
}

/* Neon Text Effect */
.neon-text {
  text-shadow: 
    0 0 5px var(--primary-glow),
    0 0 10px var(--primary-glow),
    0 0 20px var(--primary-glow);
}

/* Grid Layout */
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

@keyframes gradientAnimation {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.5) rotate(180deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}

@keyframes pulseAnimation {
  0% {
    opacity: 0.3;
    transform: scale(0.8);
  }
  50% {
    opacity: 0.5;
    transform: scale(1.2);
  }
  100% {
    opacity: 0.3;
    transform: scale(0.8);
  }
}

.app-container {
  position: relative;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.85);
}
